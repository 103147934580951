<template>
  <span />
</template>

<script>
import NoSleep from "nosleep.js"
import { Misc as DictionaryMisc } from "@/dictionary"

let instance

export default {
  name: "NoSleepObserver",
  data() {
    return {}
  },
  async created() {
    try {
      // is wrapping really necessary?
      // source https://github.com/richtr/NoSleep.js/
      await this.$info(DictionaryMisc.NO_SLEEP_REQUEST, "System", true, () => {
        instance = instance ?? new NoSleep()
        instance.enable()
      })
    } catch (e) {
      console.warn(e)
    }
  },
  beforeDestroy() {
    instance?.disable()
  }
}
</script>
